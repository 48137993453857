import React from 'react'
import './Home.css';
import Background from './Videos/Background.mp4'
import SimpleImageSlider from "react-simple-image-slider";
import { BrowserView, MobileView, } from 'react-device-detect';
import ScrollToTopNoDelay from './Features/ScrollToTop';

const images = [
  { url: "images/1.jpg" },
  { url: "images/2.jpg" },
  { url: "images/3.jpg" },
  { url: "images/4.jpg" },
  { url: "images/5.jpg" },
  { url: "images/6.jpg" },
  { url: "images/7.jpg" },
];

export default function Home(props) {
  const contentStyle = {
    color: props.theme.palette.text.primary,
    backgroundColor: props.theme.palette.background.default,
    transition: props.theme.transitions.create(),
  };

  return (
    <div>
      <ScrollToTopNoDelay />
      <BrowserView>
        <section className="banner1">
          <div className="videooverlay"><img src={require("./Modules/Images/Logo.png")} alt="" className="overlay-logo"></img></div>
          <div className="container">
            <video className='videoTag' autoPlay loop muted width={"100%"}>
              <source src={Background} type='video/mp4' />
            </video>
          </div>
        </section>

        <section>
          <h1 className="headline-small-center" style={contentStyle}><center>WIR PFLEGEN IHREN WALD - seit 1958</center></h1>
        </section>
        <section>
          <h2 className="contentbox" style={contentStyle}><center>DIEING Waldpflege führt für Sie alle Arbeiten in Ihrem Wald aus. Bis hin zur kompletten Übernahme der Waldpflege. </center></h2>
        </section>
        <section className='spaceinbetween' />
        <center>
          <SimpleImageSlider
            width={896}
            height={504}
            images={images}
            showBullets={true}
            showNavs={true}
          />
        </center>
        <section className='spaceinbetween' />
        <section className='spaceinbetween' />
      </BrowserView>


      <MobileView>
        <section className="banner1">
          <div className="videooverlay"><img src={require("./Modules/Images/Logo.png")} alt="" className="overlay-logo-mobile"></img></div>
          <div className="container">
            <video className='videoTag' autoPlay loop muted width={"100%"}>
              <source src={Background} type='video/mp4' />
            </video>
          </div>
        </section>

        <section>
          <h1 className="headline-small-center-mobile" style={contentStyle}><center>WIR PFLEGEN IHREN WALD - seit 1958</center></h1>
        </section>
        <section>
          <h2 className="contentbox-mobile" style={contentStyle}><center>DIEING Waldpflege führt für Sie alle Arbeiten in Ihrem Wald aus. Bis hin zur kompletten Übernahme der Waldpflege. </center></h2>
        </section>
        <section className='spaceinbetween' />
        <center>

          <SimpleImageSlider
            width={350}
            height={200}
            images={images}
            showBullets={true}
            showNavs={true}
          />

        </center>
        <section className='spaceinbetween' />
        <section className='spaceinbetween' />
      </MobileView>

    </div>

  )
}