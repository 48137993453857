import { React } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { BrowserView, MobileView, } from 'react-device-detect';
import ScrollToTopNoDelay from './Features/ScrollToTop';
import './Home.css';
import './AboutUs.css';
import './Leistungen.css';



export default function About(props) {
    const contentStyle = {
        color: props.theme.palette.text.primary,
        backgroundColor: props.theme.palette.background.default,
        transition: props.theme.transitions.create(),
    };

    return (
        <div>
            <ScrollToTopNoDelay />
            <MobileView>
                <section>
                    <h2 className="headline-small-center-mobile" style={contentStyle}><center>ÜBER UNS</center></h2>
                    <h2 className="headline-small-mobile" style={contentStyle}>CHRISTOPH, MANUEL UND DIETMAR DIEING </h2>
                    <font className="secondrow-mobile" style={contentStyle}>
                        Unser Großvater hat bereits 1960 als forstwirtschaftlicher Lohnunternehmer neben seinem landwirtschaftlichen Betrieb gearbeitet. Wir drei Brüder
                        sind im elterlichen Holzrückebetrieb aufgewachsen und haben nach der Ausbildung und dem Studium 2010 die „Dieings“ gegründet.Wald ist für uns
                        Lebensraum, Rückzugsort und Rohstofflieferant für den schönsten aller Rohstoffe – Holz.
                        <br /><br />
                        Durch den Einsatz optimaler Technik, gepaart mit unserer Industrieerfahrung in Produktentwicklung und Digitalisierung gelingt es uns Tag für Tag
                        konsequente, nachhaltige Waldwirtschaft zu leben und uns stetig weiterzuentwickeln. Digitalisierung,- für uns keine Worthülse,- für uns Passion
                        und Leidenschaft. Die konsequente Vernetzung jedes einzelnen Prozesses unserer Maschinen, Mitarbeiter und Kunden eröffnet unseren Kunden und uns
                        völlig neue Möglichkeiten.
                        <br /><br />
                        Ganz im Sinne unseres Slogans – Dieings, wir bewegen – ist unser Antrieb Forstdienstleistungen naturnah, nachhaltig und innovativ zu gestalten.
                        Leidenschaft für unsere Kunden und Ihren Wald.
                        <br /><br />
                        Wir sind die Gebrüder Dieing

                    </font>
                </section>


                <section className='spaceinbetween' />
                <section>
                    <h1 className="headline-small-mobile" style={contentStyle}>Unsere Leidenschaft WOOD.IN.VISION</h1>
                    <font className="secondrow-mobile" style={contentStyle}>
                        Für uns ist die Digitalisierung der Forstwirtschaft, sowie die Bereitstellung und Aufbereitung der erhobenen Daten der Schlüssel für nachhaltige
                        Produktivität. Hiermit lassen sich die bestehenden Produktivitätsgrenzen entlang der gesamten Wertschöpfungskette verschieben und auch teilweise
                        gänzlich aufheben. Vom Präventionsmonitoring aus der Luft, über die Einsatzplanung im Bestand zur Bearbeitungsmaschine / dem Personal, bis hin
                        zur Holzlogistik – alles in einem System – einfach und selbsterklärend. Daten, die zur richtigen Zeit am richtigen Ort zur Verfügung stehen,
                        optimieren Einsatzzeiten und Fahrwege – Einsatzplanung nachhaltig und klimafreundlich – . Deshalb investieren wir mit all unserer Kraft, unserem
                        Know-How, sowie mit unseren Partnern in digitale Technologien. Das Ziel: Mehrwert für alle Beteiligten in der Wertschöpfungskette und vor allem
                        für den Wald selbst.
                        <br /><br />
                        Aus diesem Grund haben wir ein Startup gegründet, dass sich vollständig diesem Thema widmet. WOOD.IN.VISION führt alle Prozesse und Teilnehmer
                        der der Forst-HolzProduktionskette in einem System zusammen. Für den Forstunternehmer, für den Forstservice und den Waldbesitzer. Eine Software
                        für alle Forst Profis. Interesse?  Dann einfach klicken..
                        <br /><br />
                        Ganz im Sinne unseres Slogans – Dieings, wir bewegen – ist unser Antrieb Forstdienstleistungen naturnah, nachhaltig und innovativ zu gestalten.
                        Leidenschaft für unsere Kunden und Ihren Wald.
                        <br /><br />
                        Wir sind die Gebrüder Dieing
                        <br /><br />
                    </font>
                </section>


                <section className='spaceinbetween' />
                <section>
                    <h1 className="headline-small-mobile" style={contentStyle}>MASCHINELLE & MANUELLE HOLZERNTE </h1>
                    <h1 className="headline-small-mobile" style={contentStyle}>--Bild--</h1>
                </section>


                <section className='spaceinbetween' />
                <br></br>
                <br></br>
                <center><Link to='https://dieings.de' class="Contact">Zu Wood.in.Vision</Link></center>
                <br></br>
                <br></br>
                <section className='spaceinbetween' />
            </MobileView>

            <BrowserView>
                <section>
                    <h2 className="headline-small-center" style={contentStyle}><center>ÜBER UNS</center></h2>
                    <h2 className="headline-small" style={contentStyle}>CHRISTOPH, MANUEL UND DIETMAR DIEING </h2>
                    <font className="secondrow" style={contentStyle}>
                        Unser Großvater hat bereits 1960 als forstwirtschaftlicher Lohnunternehmer neben seinem landwirtschaftlichen Betrieb gearbeitet. Wir drei Brüder
                        sind im elterlichen Holzrückebetrieb aufgewachsen und haben nach der Ausbildung und dem Studium 2010 die „Dieings“ gegründet.Wald ist für uns
                        Lebensraum, Rückzugsort und Rohstofflieferant für den schönsten aller Rohstoffe – Holz.
                        <br /><br />
                        Durch den Einsatz optimaler Technik, gepaart mit unserer Industrieerfahrung in Produktentwicklung und Digitalisierung gelingt es uns Tag für Tag
                        konsequente, nachhaltige Waldwirtschaft zu leben und uns stetig weiterzuentwickeln. Digitalisierung,- für uns keine Worthülse,- für uns Passion
                        und Leidenschaft. Die konsequente Vernetzung jedes einzelnen Prozesses unserer Maschinen, Mitarbeiter und Kunden eröffnet unseren Kunden und uns
                        völlig neue Möglichkeiten.
                        <br /><br />
                        Ganz im Sinne unseres Slogans – Dieings, wir bewegen – ist unser Antrieb Forstdienstleistungen naturnah, nachhaltig und innovativ zu gestalten.
                        Leidenschaft für unsere Kunden und Ihren Wald.
                        <br /><br />
                        Wir sind die Gebrüder Dieing

                    </font>
                </section>


                <section className='spaceinbetween' />
                <section>
                    <h1 className="headline-small" style={contentStyle}>Unsere Leidenschaft WOOD.IN.VISION</h1>
                    <font className="secondrow" style={contentStyle}>
                        Für uns ist die Digitalisierung der Forstwirtschaft, sowie die Bereitstellung und Aufbereitung der erhobenen Daten der Schlüssel für nachhaltige
                        Produktivität. Hiermit lassen sich die bestehenden Produktivitätsgrenzen entlang der gesamten Wertschöpfungskette verschieben und auch teilweise
                        gänzlich aufheben. Vom Präventionsmonitoring aus der Luft, über die Einsatzplanung im Bestand zur Bearbeitungsmaschine / dem Personal, bis hin
                        zur Holzlogistik – alles in einem System – einfach und selbsterklärend. Daten, die zur richtigen Zeit am richtigen Ort zur Verfügung stehen,
                        optimieren Einsatzzeiten und Fahrwege – Einsatzplanung nachhaltig und klimafreundlich – . Deshalb investieren wir mit all unserer Kraft, unserem
                        Know-How, sowie mit unseren Partnern in digitale Technologien. Das Ziel: Mehrwert für alle Beteiligten in der Wertschöpfungskette und vor allem
                        für den Wald selbst.
                        <br /><br />
                        Aus diesem Grund haben wir ein Startup gegründet, dass sich vollständig diesem Thema widmet. WOOD.IN.VISION führt alle Prozesse und Teilnehmer
                        der der Forst-HolzProduktionskette in einem System zusammen. Für den Forstunternehmer, für den Forstservice und den Waldbesitzer. Eine Software
                        für alle Forst Profis. Interesse?  Dann einfach klicken..
                        <br /><br />
                        Ganz im Sinne unseres Slogans – Dieings, wir bewegen – ist unser Antrieb Forstdienstleistungen naturnah, nachhaltig und innovativ zu gestalten.
                        Leidenschaft für unsere Kunden und Ihren Wald.
                        <br /><br />
                        Wir sind die Gebrüder Dieing
                        <br /><br />
                    </font>
                </section>


                <section className='spaceinbetween' />
                <section>
                    <h1 className="headline-small" style={contentStyle}>MASCHINELLE & MANUELLE HOLZERNTE </h1>
                    <h1 className="headline-small" style={contentStyle}>--Bild--</h1>
                </section>


                <section className='spaceinbetween' />
                <br></br>
                <br></br>
                <center><Link to='https://dieings.de' class="Contact">Zu Wood.in.Vision</Link></center>
                <br></br>
                <br></br>
                <section className='spaceinbetween' />
            </BrowserView>

        </div>

    )
}