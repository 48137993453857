import React, { useState } from 'react';
import './Leistungen.css';
import './Home.css';
import { BrowserView, MobileView, } from 'react-device-detect';
import SimpleImageSlider from "react-simple-image-slider";
import ScrollToTopNoDelay from './Features/ScrollToTop';

const images = [
    { url: "images/1.jpg" },
    { url: "images/2.jpg" },
    { url: "images/3.jpg" },
    { url: "images/4.jpg" },
    { url: "images/5.jpg" },
    { url: "images/6.jpg" },
    { url: "images/7.jpg" },
];

export default function Leistungen(props) {
    const contentStyle = {
        color: props.theme.palette.text.primary,
        backgroundColor: props.theme.palette.background.default,
        transition: props.theme.transitions.create(),
    };


    return (
        <div style={contentStyle}>
            <ScrollToTopNoDelay />
            <MobileView>
                <section>
                    <h2 className="headline-small-center-mobile" style={contentStyle}><center>UNSERE LEISTUNGEN</center></h2>
                    <h2 className="headline-small-mobile" style={contentStyle}>Nachhaltige Flächenvorbereitung, Pflanzung und Kulturpflege</h2>
                    <font className="secondrow-mobile" style={contentStyle}>
                        Damit Wald auch kommenden Generationen zur Verfügung steht, bieten wir auf ihre Bedürfnisse abgestimmte Kulturbgründungsmaßnahmen an.
                        Wir sind mit unseren Mitarbeitern mit einer einzigartigen digitalen Planung über unser Waldmanagement Tool WOO.IN.VISION bereits bei
                        der Flächenbearbeitung präzise unterwegs. Ob Mulchen, Pflanzmengen oder Freischneiden gepflanzter Flächen. Bei uns ist alles digital
                        erfasst, wir vergessen keine Maßnahme unserer Kunden. Neueste Technik ausgebildete Forstwirte und Förster garantieren Ihnen eine
                        exzellente Pflege Ihres Waldes, bereits von Kindesbeinen an.
                    </font>
                    <section className='spaceinbetween' />
                    <center>

                        <SimpleImageSlider
                            width={350}
                            height={200}
                            images={images}
                            showBullets={true}
                            showNavs={true}
                        />

                    </center>

                </section>

                <section className='spaceinbetween' />

                <section>
                    <h2 className="headline-small-mobile" style={contentStyle}>Hochgenaue Schadholzüberwachung</h2>

                    <font className="secondrow-mobile" style={contentStyle}>
                        Wir überfliegen mit RTK-Drohnen die Wälder unserer Kunden um Sturm und Schadholz direkt aus der Luft zu erkennen und digital (per GPS-Punkt)
                        verortet unsere Einsätze zu planen. Selbstverständlich sind wir auch im Wald unterwegs um jederzeit den Gesundheitszustand sowie die
                        Naturverjüngung zu kontrollieren.

                    </font>
                    <section className='spaceinbetween' />
                    <center>

                        <SimpleImageSlider
                            width={350}
                            height={200}
                            images={images}
                            showBullets={true}
                            showNavs={true}
                        />

                    </center>
                </section>

                <section className='spaceinbetween' />

                <section>
                    <h2 className="headline-small-mobile" style={contentStyle}>Maschinelle & Manuelle Holzernte</h2>

                    <font className="secondrow-mobile" style={contentStyle}>
                        Je nach Gelände und Umfang bieten wir bodenschonende Harvestereinsätze oder auch händische Hiebe an. In Zusammenarbeit mit lokalen Rückeunternehmen
                        wird das eingeschlagene Holz abfahrbereit zur Verfügung gestellt. Wir setzen ausschließlich auf Maschinen von Weltmarktführern, um einen möglichst
                        störungsfreien Betrieb an unseren Einsatzorten zu gewährleisten. Höchste Qualität und immer optimal gewartetes Equipment. Ein maschinenunabhängiger,
                        voll digitalisierter Auftragsfluss ermöglicht uns zusätzlich die Aufträge zielgenau zu planen und „Just-in-Time“ umzusetzen. Volle Information
                        jederzeit für unsere Kunden.

                        Unser Leistungsportfolio in der Holzernte:
                        <br /><br />
                        Raupenharvester
                        <br /><br />
                        8-Rad Harvester
                        <br /><br />
                        8-Rad Rückezug

                    </font>
                    <section className='spaceinbetween' />
                    <center>

                        <SimpleImageSlider
                            width={350}
                            height={200}
                            images={images}
                            showBullets={true}
                            showNavs={true}
                        />

                    </center>
                </section>

                <section className='spaceinbetween' />

                <section>
                    <h2 className="headline-small-mobile" style={contentStyle}>Holzverkauf</h2>

                    <font className="secondrow-mobile" style={contentStyle}>
                        Wir übernehmen bei Bedarf auch die Holzvermarktung. Mit unseren Kontakten in die Holzindustrie haben wir immer den optimalen Kunden für Ihr Holz.

                    </font>
                    <section className='spaceinbetween' />
                    <center>

                        <SimpleImageSlider
                            width={350}
                            height={200}
                            images={images}
                            showBullets={true}
                            showNavs={true}
                        />

                    </center>
                </section>

                <section className='spaceinbetween' />

                <section>
                    <h2 className="headline-small-mobile" style={contentStyle}>Vollumfängliche Waldpflegeverträge</h2>

                    <font className="secondrow-mobile" style={contentStyle}>
                        Mit dem Ziel stabile und ertragseiche Wälder zu erziehen bieten wir umfangreiche Kulturpflegemaßnahmen von Begleitwuchsreguliereung bis zu Jungdurchforstungen an.
                        <br /><br />
                        Die Dieing Waldpflege mit ihrem Team bietet allen Waldbesitzern, die entfernt von Ihrem Wald wohnen und somit die Arbeiten selbst nicht durchführen und leiten können
                        oder Waldeigentümer, die aus gesundheitlichen Gründen oder altersbedingt die anstehenden Arbeiten nicht bewältigen können, ein umfassendes Betreuungsangebot in
                        Form eines Waldpflegevertrages an.
                        <br /><br />
                        Im Rahmen eines Waldpflegevertrages übernimmt die Dieing Waldpflege die Bewirtschaftung der Fläche in enger Absprache mit Ihnen als Waldbesitzer. Wir setzen bei
                        der Dieing Waldpflege hauptsächlich eigenes Personal eigene Maschinen und bei Bedarf unsere zertifizierten Partner ein. Wir führen alle Tätigkeiten von der Pflanzung
                        bis zur Holzvermarktung inklusive der Abrechnung für Sie aus. Je nach dem was und wieviel Sie in Ihrem Wald selbst erledigen wollen. Alle erfassten Daten werden von
                        uns digital für Sie in unserer „Mein Wald“ Plattform hinterlegt. Sie haben jederzeit die Möglichkeit Ihren Wald über ein aktuelles Luftbild zu sehen und das digitale
                        Revierbuch zeigt Ihnen den Lebenslauf samt den anstehenden Arbeiten. Lebenslauf Ihres Waldes immer im Blick.
                    </font>
                    <section className='spaceinbetween' />
                    <center>

                        <SimpleImageSlider
                            width={350}
                            height={200}
                            images={images}
                            showBullets={true}
                            showNavs={true}
                        />

                    </center>
                </section>

                <section className='spaceinbetween' />

                <section>
                    <h2 className="headline-small-mobile" style={contentStyle}>UNSERE KUNDEN</h2>

                    <font className="secondrow-mobile" style={contentStyle}>
                        Forstrevier Meersburg
                        <br /><br />

                        Forstrevier Bodenseekreis
                        <br /><br />

                        Stiftung Liebenau
                        <br /><br />
                        Unsere kompetenten Förster, Försterinnen und Forstwirte stehen Ihnen gerne beratend zur Seite, um gemeinsam ein gutes Konzept zur Bewirtschaftung Ihres Waldes zu entwickeln.

                        Sprechen Sie uns an wir sind für Sie da.

                    </font>
                    <section className='spaceinbetween' />
                    <center>

                        <SimpleImageSlider
                            width={350}
                            height={200}
                            images={images}
                            showBullets={true}
                            showNavs={true}
                        />

                    </center>
                </section>

                <section className='spaceinbetween' />

            </MobileView>


            <BrowserView>
                <section>
                    <h2 className="headline-small-center" style={contentStyle}><center>UNSERE LEISTUNGEN</center></h2>
                    <h2 className="headline-small" style={contentStyle}>Nachhaltige Flächenvorbereitung, Pflanzung und Kulturpflege</h2>
                    <font className="secondrow" style={contentStyle}>
                        Damit Wald auch kommenden Generationen zur Verfügung steht, bieten wir auf ihre Bedürfnisse abgestimmte Kulturbgründungsmaßnahmen an.
                        Wir sind mit unseren Mitarbeitern mit einer einzigartigen digitalen Planung über unser Waldmanagement Tool WOO.IN.VISION bereits bei
                        der Flächenbearbeitung präzise unterwegs. Ob Mulchen, Pflanzmengen oder Freischneiden gepflanzter Flächen. Bei uns ist alles digital
                        erfasst, wir vergessen keine Maßnahme unserer Kunden. Neueste Technik ausgebildete Forstwirte und Förster garantieren Ihnen eine
                        exzellente Pflege Ihres Waldes, bereits von Kindesbeinen an.
                    </font>
                    <section className='spaceinbetween' />
                    <center>
                        <SimpleImageSlider
                            width={896}
                            height={504}
                            images={images}
                            showBullets={true}
                            showNavs={true}
                        />
                    </center>
                </section>

                <section className='spaceinbetween' />

                <section>
                    <h2 className="headline-small" style={contentStyle}>Hochgenaue Schadholzüberwachung</h2>

                    <font className="secondrow" style={contentStyle}>
                        Wir überfliegen mit RTK-Drohnen die Wälder unserer Kunden um Sturm und Schadholz direkt aus der Luft zu erkennen und digital (per GPS-Punkt)
                        verortet unsere Einsätze zu planen. Selbstverständlich sind wir auch im Wald unterwegs um jederzeit den Gesundheitszustand sowie die
                        Naturverjüngung zu kontrollieren.

                    </font>
                    <section className='spaceinbetween' />
                    <center>
                        <SimpleImageSlider
                            width={896}
                            height={504}
                            images={images}
                            showBullets={true}
                            showNavs={true}
                        />
                    </center>
                </section>

                <section className='spaceinbetween' />

                <section>
                    <h2 className="headline-small" style={contentStyle}>Maschinelle & Manuelle Holzernte</h2>

                    <font className="secondrow" style={contentStyle}>
                        Je nach Gelände und Umfang bieten wir bodenschonende Harvestereinsätze oder auch händische Hiebe an. In Zusammenarbeit mit lokalen Rückeunternehmen
                        wird das eingeschlagene Holz abfahrbereit zur Verfügung gestellt. Wir setzen ausschließlich auf Maschinen von Weltmarktführern, um einen möglichst
                        störungsfreien Betrieb an unseren Einsatzorten zu gewährleisten. Höchste Qualität und immer optimal gewartetes Equipment. Ein maschinenunabhängiger,
                        voll digitalisierter Auftragsfluss ermöglicht uns zusätzlich die Aufträge zielgenau zu planen und „Just-in-Time“ umzusetzen. Volle Information
                        jederzeit für unsere Kunden.

                        Unser Leistungsportfolio in der Holzernte:
                        <br /><br />
                        Raupenharvester
                        <br /><br />
                        8-Rad Harvester
                        <br /><br />
                        8-Rad Rückezug

                    </font>
                    <section className='spaceinbetween' />
                    <center>
                        <SimpleImageSlider
                            width={896}
                            height={504}
                            images={images}
                            showBullets={true}
                            showNavs={true}
                        />
                    </center>
                </section>

                <section className='spaceinbetween' />

                <section>
                    <h2 className="headline-small" style={contentStyle}>Holzverkauf</h2>

                    <font className="secondrow" style={contentStyle}>
                        Wir übernehmen bei Bedarf auch die Holzvermarktung. Mit unseren Kontakten in die Holzindustrie haben wir immer den optimalen Kunden für Ihr Holz.

                    </font>
                    <section className='spaceinbetween' />
                    <center>
                        <SimpleImageSlider
                            width={896}
                            height={504}
                            images={images}
                            showBullets={true}
                            showNavs={true}
                        />
                    </center>
                </section>

                <section className='spaceinbetween' />

                <section>
                    <h2 className="headline-small" style={contentStyle}>Vollumfängliche Waldpflegeverträge</h2>

                    <font className="secondrow" style={contentStyle}>
                        Mit dem Ziel stabile und ertragseiche Wälder zu erziehen bieten wir umfangreiche Kulturpflegemaßnahmen von Begleitwuchsreguliereung bis zu Jungdurchforstungen an.
                        <br /><br />
                        Die Dieing Waldpflege mit ihrem Team bietet allen Waldbesitzern, die entfernt von Ihrem Wald wohnen und somit die Arbeiten selbst nicht durchführen und leiten können
                        oder Waldeigentümer, die aus gesundheitlichen Gründen oder altersbedingt die anstehenden Arbeiten nicht bewältigen können, ein umfassendes Betreuungsangebot in
                        Form eines Waldpflegevertrages an.
                        <br /><br />
                        Im Rahmen eines Waldpflegevertrages übernimmt die Dieing Waldpflege die Bewirtschaftung der Fläche in enger Absprache mit Ihnen als Waldbesitzer. Wir setzen bei
                        der Dieing Waldpflege hauptsächlich eigenes Personal eigene Maschinen und bei Bedarf unsere zertifizierten Partner ein. Wir führen alle Tätigkeiten von der Pflanzung
                        bis zur Holzvermarktung inklusive der Abrechnung für Sie aus. Je nach dem was und wieviel Sie in Ihrem Wald selbst erledigen wollen. Alle erfassten Daten werden von
                        uns digital für Sie in unserer „Mein Wald“ Plattform hinterlegt. Sie haben jederzeit die Möglichkeit Ihren Wald über ein aktuelles Luftbild zu sehen und das digitale
                        Revierbuch zeigt Ihnen den Lebenslauf samt den anstehenden Arbeiten. Lebenslauf Ihres Waldes immer im Blick.
                    </font>
                    <section className='spaceinbetween' />
                    <center>
                        <SimpleImageSlider
                            width={896}
                            height={504}
                            images={images}
                            showBullets={true}
                            showNavs={true}
                        />
                    </center>
                </section>

                <section className='spaceinbetween' />

                <section>
                    <h2 className="headline-small" style={contentStyle}>UNSERE KUNDEN </h2>

                    <font className="secondrow" style={contentStyle}>
                        Forstrevier Meersburg
                        <br /><br />

                        Forstrevier Bodenseekreis
                        <br /><br />

                        Stiftung Liebenau
                        <br /><br />
                        Unsere kompetenten Förster, Försterinnen und Forstwirte stehen Ihnen gerne beratend zur Seite, um gemeinsam ein gutes Konzept zur Bewirtschaftung Ihres Waldes zu entwickeln.

                        Sprechen Sie uns an wir sind für Sie da.

                    </font>
                    <section className='spaceinbetween' />
                    <center>
                        <SimpleImageSlider
                            width={896}
                            height={504}
                            images={images}
                            showBullets={true}
                            showNavs={true}
                        />
                    </center>
                </section>

                <section className='spaceinbetween' />

                <section className='spaceinbetween' />
                <section className='spaceinbetween' />
            </BrowserView>
        </div >
    );
}
